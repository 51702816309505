import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams, useBlocker } from 'react-router-dom'
import { getCurrentEditorValue, getEditingTextId, restoreBackup, saveText } from '../app/editor'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { AppDispatch } from '../app/store'
import Editor from '../components/editor/Editor'
import PageLoad from '../components/loading/PageLoad'
import AlertModal from '../components/modals/AlertModal'
import { fetchText } from '../repository/Fetch'
import { TextData } from '../repository/Models'
import { clearTemporaryText, getTemporaryText, saveTemporaryText } from '../storage/Draft'
import { dismissModal, showModal } from '../store/modalSlice'
import { NetworkStateReducer, NetworkStoreContext } from '../store/NetworkStore'
import { selectText, replaceText, selectTextTimestamp } from '../store/serverSlice'

function useBackup (temporaryText: string | null, textId: number): React.MutableRefObject<string | null> {
  const dispatch = useAppDispatch()
  const pendingTemporaryText = useRef<string | null>(null)
  pendingTemporaryText.current = temporaryText
  useEffect(() => {
    if (!temporaryText) {
      return
    }
    const start = performance.now()
    if (window.confirm('前回未保存のテキストを復元しますか？')) {
      restoreBackup(temporaryText, textId)
      pendingTemporaryText.current = null
      return
    }
    const end = performance.now()
    if (start + 10 > end) {
      dispatch(showModal({
        component () {
          return <AlertModal
            message={'前回未保存のテキストを復元しますか？'}
            positive={{
              label: '復元',
              onClick: () => {
                dispatch(dismissModal())
                restoreBackup(temporaryText, textId)
                pendingTemporaryText.current = null
              }
            }}
            negative={{
              label: '無視',
              onClick: () => {
                dispatch(dismissModal())
                pendingTemporaryText.current = null
              }
            }}
          />
        }
      }))
    }
  }, [temporaryText])
  return pendingTemporaryText
}

function useBlock (initialValue: string, clearBackup: () => void, backupText: (text: string) => void, saveText: (text: string) => void) {
  const dispatch = useAppDispatch()
  const discardText = useRef<string | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate()

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    // Skip if it's the same location (REPLACE action)
    if (currentLocation.pathname === nextLocation.pathname) {
      return false
    }

    const currentValute = getCurrentEditorValue()
    if (discardText.current === currentValute) {
      return false
    }

    if (initialValue === currentValute || ((initialValue.search('\r') >= 0 || currentValute.search('\r') >= 0) && initialValue.replaceAll('\r', '') === currentValute.replaceAll('\r', ''))) {
      clearBackup()
      return false
    }

    return true
  })

  useEffect(() => {
    if (blocker.state === 'blocked') {
      const start = performance.now()
      const ok = window.confirm('このページから移動しますか？')
      const end = performance.now()

      if (ok) {
        blocker.proceed()
        return
      }

      if (start + 10 > end) {
        // less than 10 ms
        // show save modal for Safari for iOS
        dispatch(showModal({
          component () {
            return <AlertModal
              message={'テキストを保存しますか？'}
              positive={{
                label: '保存',
                onClick: () => {
                  dispatch(dismissModal())
                  saveText(getCurrentEditorValue())
                  discardText.current = getCurrentEditorValue()
                  blocker.proceed()
                }
              }}
              negative={{
                label: '保留',
                onClick: () => {
                  dispatch(dismissModal())
                  backupText(getCurrentEditorValue())
                  discardText.current = getCurrentEditorValue()
                  blocker.proceed()
                }
              }}
            />
          }
        }))
      } else {
        blocker.reset()
      }
    }
  }, [blocker])
}

const TextEditor: React.FC<{
  text: TextData
  temporaryText: string | null
}> = props => {
  const pendingTemporaryText = useBackup(props.temporaryText, props.text.id)
  const initialValue = useMemo(() => {
    return props.text.text
  }, [props.text.text])
  const networkStore = useContext(NetworkStoreContext)
  const dispatch = useAppDispatch()
  useBlock(
    initialValue,
    () => clearTemporaryText(props.text.id),
    text => saveTemporaryText(props.text.id, text),
    () => saveText(props.text, networkStore, dispatch)
  )
  return <Editor
    initialValue={initialValue ?? ''}
    text={props.text}
    backupText={textArea => {
      const text = textArea.value
      if (pendingTemporaryText.current && pendingTemporaryText.current === text) {
        // confirm modal
      } else if (initialValue === text) {
        clearTemporaryText(props.text.id)
      } else {
        saveTemporaryText(props.text.id, text)
        if ((initialValue.search('\r') >= 0 || text.search('\r') >= 0) && initialValue.replaceAll('\r', '') === text.replaceAll('\r', '')) {
          clearTemporaryText(props.text.id)
        }
      }
    }}
  />
}

function dispatchNewText (dispatch: AppDispatch, oldText: TextData, newText: TextData) {
  if (newText.id !== getEditingTextId()) {
    return
  }
  if (oldText.characterCount !== newText.characterCount || oldText.lineCount !== newText.lineCount || oldText.text !== newText.text) {
    dispatch(showModal({
      component () {
        return <AlertModal
          message='サーバーに新しいテキストがあります'
          positive={{
            label: '開く',
            onClick () {
              dispatch(dismissModal())
              dispatch(replaceText(newText))
              restoreBackup(newText.text, newText.id)
            }
          }}
          negative={{
            label: '無視する',
            onClick () {
              dispatch(dismissModal())
            }
          }}
        />
      }
    }))
  }
}

const TextEdit: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>()
  const textId = parseInt(id || '0')
  const dispatch = useAppDispatch()
  const text = useAppSelector(state => selectText(state, textId))
  const timestamp = useAppSelector(state => selectTextTimestamp(state, textId))
  const [temporaryText, setTemporaryText] = useState<string | null | false>(false)
  if (!text) {
    return <PageLoad<[{ text: TextData }, string | null]>
      fetch={accessToken => Promise.all([fetchText(textId, accessToken), getTemporaryText(textId)])}
      dispatch={response => {
        dispatch(replaceText(response[0].text))
        setTemporaryText(response[1])
      }}
    />
  }
  if (temporaryText === false) {
    return <PageLoad<[NetworkStateReducer, string | null]>
      fetch={accessToken => Promise.all([accessToken, getTemporaryText(textId)])}
      dispatch={response => {
        setTemporaryText(response[1])
        if (response[1] === null && timestamp && timestamp + 3 * 60 * 1000 < new Date().getTime()) {
          fetchText(textId, response[0])
            .then(response => dispatchNewText(dispatch, text, response.text))
        }
      }}
    />
  }
  return <TextEditor text={text} temporaryText={temporaryText}/>
}

export default TextEdit
