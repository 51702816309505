import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { createNewText, dummyTextareaId, saveText, initializeEditingTextId, textareaId } from '../../app/editor'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { TextData } from '../../repository/Models'
import { onEdit, selectEditorSettings } from '../../store/editorSlice'
import { NetworkStoreContext } from '../../store/NetworkStore'
import DropDownMenu from './DropDownMenu'

const StyledTextArea = styled.textarea`
outline: none;
resize: none;
background: none;
-webkit-tap-highlight-color: transparent;
font-family: sans-serif;
&:lang(ja) {
  font-family: 'ヒラギノ角ゴ Pro', '游ゴシック体', 'Noto Sans JP', sans-serif;
}
&.mincho {
  font-family: serif;
  &:lang(ja) {
    font-family: 'ヒラギノ明朝 Pro', '游明朝体', 'Noto Serif JP', serif;
  }
}
`

interface EditorState {
  characterCount: number
  initialCharacterCount: number
}

function calcCharacterCountDiff (state: EditorState) {
  const diff = state.characterCount - state.initialCharacterCount
  if (diff > 0) {
    return `+${diff}`
  }
  if (diff < 0) {
    return `${diff}`
  }
  return '±0'
}

function useBackup (backupText: (textArea: HTMLTextAreaElement) => void) {
  const mainTextArea = useRef<HTMLTextAreaElement>(null)
  useEffect(() => {
    const textArea = mainTextArea.current
    const listener = () => {
      if (textArea) {
        backupText(textArea)
      }
    }
    window.addEventListener('unload', listener)
    window.addEventListener('pagehide', listener)
    document.addEventListener('visibilitychange', listener)
    textArea?.addEventListener('blur', listener)
    return () => {
      listener()
      window.removeEventListener('unload', listener)
      window.removeEventListener('pagehide', listener)
      document.removeEventListener('visibilitychange', listener)
      textArea?.removeEventListener('blur', listener)
    }
  }, [backupText])
  return mainTextArea
}

function useKeyboardShortcut (text: TextData | undefined) {
  const dispatch = useAppDispatch()
  const network = useContext(NetworkStoreContext)
  const navigate = useNavigate()
  useEffect(() => {
    const listener: (this: Document, ev: KeyboardEvent) => any = (ev) => {
      if (ev.ctrlKey || ev.metaKey) {
        if (ev.key === 's') {
          ev.preventDefault()
          if (text) {
            saveText(text, network, dispatch)
          } else {
            createNewText(network, dispatch, text => {
              navigate(`/texts/${text.id}/edit`, { replace: true })
            })
          }
        }
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [text])
}

const Editor: React.FC<{
  initialValue: string,
  text?: TextData
  backupText (textArea: HTMLTextAreaElement): void
}> = props => {
  const [state, setState] = useState<EditorState>({
    characterCount: props.initialValue.length,
    initialCharacterCount: props.initialValue.length
  })
  const mainTextArea = useBackup(props.backupText)
  const settings = useAppSelector(selectEditorSettings)
  const dispatch = useDispatch()
  useEffect(() => {
    // push defaultValue
    dispatch(onEdit())
  }, [])
  useKeyboardShortcut(props.text)
  initializeEditingTextId(props.text?.id ?? null)
  // -mb-16pxはSafariでコンテンツ全体が無意味にスクロールされるバグ回避
  return <div className={'w-full h-full'}>
    <StyledTextArea
      id={dummyTextareaId}
      className={`block opacity-0 w-full h-0 max-h-0 px-16px md:px-40px ${settings.minchoEnabled ? 'mincho' : 'gothic'}`}
      spellCheck="false"
      wrap={settings.nowrapEnabled ? 'off' : undefined}
      style={{
        fontSize: `${settings.fontSize}px`
      }}
    />
    <StyledTextArea
      id={textareaId}
      className={`w-full h-screen -mb-16px -mt-heightHeader p-16px md:p-40px pt-120px md:pt-120px ${settings.minchoEnabled ? 'mincho' : 'gothic'}`}
      spellCheck="false"
      ref={mainTextArea}
      defaultValue={props.initialValue ?? ''}
      placeholder="本文を入力"
      wrap={settings.nowrapEnabled ? 'off' : undefined}
      style={{
        fontSize: `${settings.fontSize}px`
      }}
      onChange={e => {
        setState({
          ...state,
          characterCount: e.target.value.length
        })
        dispatch(onEdit())
      }}
    />
    <div className={'border-b border-lightBorder dark:border-darkBorder w-full fixed items-center bg-lightSurface dark:bg-darkSurface flex h-16px pr-16px pl-8px md:pl-32px top-heightHeader'}>
      <DropDownMenu text={props.text}/>
    </div>
    <footer className={'fixed items-center bg-lightSurface dark:bg-darkSurface text-12px mx-16px md:mx-40px bottom-0'}>
      文字数: {state.characterCount} ({calcCharacterCountDiff(state)})
    </footer>
  </div>
}

export default Editor
