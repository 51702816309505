import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Project from '../pages/Project'
import New from '../pages/New'
import TextEdit from '../pages/TextEdit'
import Texts from '../pages/Texts'
import Home from '../pages/Home'
import About from '../pages/About'
import Contact from '../pages/Contact'
import Privacy from '../pages/Privacy'
import Tos from '../pages/Tos'
import Me from '../pages/Me'
import SignIn from '../pages/SignIn'
import { selectHasLoggedIn } from '../store/userSlice'
import { useAppSelector } from '../app/hooks'
import Help from '../pages/Help'
import Auth from '../pages/Auth'
import Credentials from '../pages/Credentials'

const Page: React.FC<{}> = () => {
  const hasLoggedIn = useAppSelector(selectHasLoggedIn)
  if (hasLoggedIn) {
    return <Routes>
      <Route path="/new" element={<New />} />
      <Route path="/texts/:id/edit" element={<TextEdit />} />
      <Route path="/projects/:id" element={<Project />} />
      <Route path="/texts" element={<Texts />} />
      <Route path="/home" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/tos" element={<Tos />} />
      <Route path="/me" element={<Me />} />
      <Route path="/auth/authorization" element={<Auth />} />
      <Route path="/auth/credentials" element={<Credentials />} />
      <Route path="/help/*" element={<Help />} />
      <Route path="*" element={<Navigate to="/home" replace />} />
    </Routes>
  } else {
    return <Routes>
      <Route path="/" element={<About />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="/tos" element={<Tos />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/auth/authorization" element={<SignIn />} />
      <Route path="/help/*" element={<Help />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  }
}

export default Page
