import React, { useContext } from 'react'
import { ReactComponent as MoreVertIcon } from '@material-design-icons/svg/filled/more_vert.svg'
import { characterCountOfSection, titleOfSection, ProjectListItemData, displayNumberOfSection, updateTimeOfSection, TextSectionListItemData } from '../../../repository/Models'
import Sortable from '../Sortable'
import { showSectionContextMenu } from '../../../app/server'
import { useAppDispatch } from '../../../app/hooks'
import { NetworkStoreContext } from '../../../store/NetworkStore'

export interface SectionCardProps {
  index: number
  project: ProjectListItemData
  section: TextSectionListItemData
}

const SectionCard: React.FC<SectionCardProps> = props => {
  const dispatch = useAppDispatch()
  const network = useContext(NetworkStoreContext)
  const path = `/texts/${props.section.text.id}/edit`
  return <Sortable type={'card'} item={{ index: props.index, section: props.section, project: props.project }} path={path}>
    <div className={'flex-grow py-8px pl-16px pr-4px flex items-center'}>
      <span className={'flex-grow'}>{titleOfSection(props.section)}</span>
      <MoreVertIcon className={'shrink-0 p-8px w-40px h-40px rounded-full hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'} onClick={e => {
        showSectionContextMenu(e, dispatch, network, props.section, props.project)
        e.preventDefault()
      }} />
    </div>
    <div className={'px-16px pb-12px grow-0 text-right'}>{characterCountOfSection(props.section)}</div>
    <div className={'px-16px pb-16px grow-0 text-right'}>{updateTimeOfSection(props.section)}</div>
    <div className={'p-16px grow-0 border-t border-lightBorder dark:border-darkBorder'}>{displayNumberOfSection(props.section)}</div>
  </Sortable>
}

export default SectionCard
