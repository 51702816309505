import React from 'react'
import styled from 'styled-components'
import { ReactComponent as ViewListIcon } from '@material-design-icons/svg/filled/view_list.svg'
import { ReactComponent as ViewModuleIcon } from '@material-design-icons/svg/filled/view_module.svg'

const StyledButtonContainer = styled.div`
& > * {
  margin-right: 8px;
  &:last-of-type {
    margin-right: 4px;
  }
}
`

export interface ListHeaderProps {
  title: string
  changeToListView: () => void
  changeToCardView: () => void
}

const ListHeader: React.FC<ListHeaderProps> = props => {
  return <div className={'py-8px md:py-16px flex justify-between items-center align-bottom'}>
    <h2 className={'ml-16px'}>{props.title}</h2>
    <StyledButtonContainer className={'flex'}>
      <ViewListIcon className={'w-40px h-40px p-8px rounded-full cursor-pointer hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'} onClick={props.changeToListView}/>
      <ViewModuleIcon className={'w-40px h-40px p-8px rounded-full cursor-pointer hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'} onClick={props.changeToCardView}/>
    </StyledButtonContainer>
  </div>
}

export default ListHeader
