import React, { useContext, useRef } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { feedback } from '../../app/server'
import { dismissModal } from '../../store/modalSlice'
import { showToast } from '../../store/toastSlice'
import { selectHasLoggedIn } from '../../store/userSlice'
import { ReactComponent as OpenInNewIcon } from '@material-design-icons/svg/filled/open_in_new.svg'
import { NetworkStoreContext } from '../../store/NetworkStore'

const HelpModal: React.FC<{
  subtype: 'notImplemented' | 'notFound' | 'notEnough'
}> = props => {
  const agreementCheckboxId = 'checkbox_agreement'
  const privacyCheckboxId = 'checkbox_privacy'
  const hasLoggedIn = useAppSelector(selectHasLoggedIn)
  const networkStore = useContext(NetworkStoreContext)
  const dispatch = useAppDispatch()
  const textArea = useRef<HTMLTextAreaElement | null>(null)
  return <div className={'p-16px'}>
    <h2 className={'mb-8px'}>ヘルプ記事へのフィードバック</h2>
    <textarea ref={textArea} className={'mb-8px w-full border h-120px'} placeholder={'フィードバックを入力'}/>
    <p className={'mb-8px text-12px'}>フィードバックへの返信は行っていませんのでご了承ください。</p>
    <div className='mb-8px text-12px'>
      <input type="checkbox" id={privacyCheckboxId}/>
      <label htmlFor={privacyCheckboxId}><a href='/privacy' target='_blank' className={'underline'}>プライバシーポリシー<OpenInNewIcon className={'w-12px h-12px inline-block'}/></a>を確認しました。</label>
    </div>
    <div className='mb-8px text-12px'>
      <input type="checkbox" id={agreementCheckboxId}/>
      <label htmlFor={agreementCheckboxId}>送信内容をヘルプ記事の作成に利用することに同意します。</label>
    </div>
    <div className={'flex justify-end'}>
      <button className={'btn-normal mr-8px'} onClick={() => {
        dispatch(dismissModal())
      }}>キャンセル</button>
      <button className={'btn-primary'} onClick={() => {
        const agreementCheckbox = document.getElementById(agreementCheckboxId) as HTMLInputElement | null
        const privacyCheckbox = document.getElementById(privacyCheckboxId) as HTMLInputElement | null
        if (agreementCheckbox?.checked && privacyCheckbox?.checked) {
          feedback('help', props.subtype, textArea.current?.value ?? '', dispatch, hasLoggedIn, networkStore)
        } else {
          dispatch(showToast({ type: 'warning', message: 'チェックボックスを確認してください' }))
        }
      }}>送信</button>
    </div>
  </div>
}

export default HelpModal
