import React from 'react'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import { ReactComponent as DecriptionIcon } from '@material-design-icons/svg/filled/description.svg'

const DropZoneModal: React.FC<{
  onDrop: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void
}> = props => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'text/*': []
    },
    multiple: false,
    onDrop: props.onDrop
  })
  return <div className={'flex flex-col items-center justify-center w-full h-240px cursor-pointer rounded-8px'} {...getRootProps()}>
    <input {...getInputProps()}/>
    <DecriptionIcon className={'w-96px h-96px m-16px'}/>
    {isDragActive ? <p>ここにドロップ</p> : <p>ここをクリック / ここにドロップ</p>}
  </div>
}

export default DropZoneModal
