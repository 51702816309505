import axios, { AxiosInstance } from 'axios'
import { TATEDITOR_API } from './ApiServer'
import { Models } from './Models'

export function createAnonymousRequest (): AxiosInstance {
  const request = axios.create({
    baseURL: TATEDITOR_API
  })
  return request
}

export async function postAnonymousFeedback (type: 'help' | 'feedback', subtype: string, message: string): Promise<{}> {
  return new Promise<{}>((resolve, reject) => {
    createAnonymousRequest()
      .post('/feedback', {
        type,
        subtype,
        message
      })
      .then(res => resolve(res.data))
      .catch(reject)
  })
}

export function createRequest (accessToken: string): AxiosInstance {
  const request = axios.create({
    baseURL: TATEDITOR_API,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    headers: { Authorization: `Bearer ${accessToken}` }
  })
  return request
}

async function request<T> (method: 'delete' | 'get', path: string, accessToken: string): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    createRequest(accessToken)[method]<T>(path)
      .then(res => resolve(res.data))
      .catch(reject)
  })
}

async function requestWithBody<T> (method: 'put' | 'post' | 'patch', path: string, accessToken: string, body?: any): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    createRequest(accessToken)[method]<T>(path, body)
      .then(res => resolve(res.data))
      .catch(reject)
  })
}

export async function requestDelete<T> (path: string, accessToken: string): Promise<T> {
  return request('delete', path, accessToken)
}

export async function requestGet<T> (path: string, accessToken: string): Promise<T> {
  return request('get', path, accessToken)
}

export async function requestPatch<T> (path: string, accessToken: string, body?: any): Promise<T> {
  return requestWithBody('patch', path, accessToken, body)
}

export async function requestPost<T> (path: string, accessToken: string, body?: any): Promise<T> {
  return requestWithBody('post', path, accessToken, body)
}

export async function requestPut<T> (path: string, accessToken: string, body?: any): Promise<T> {
  return requestWithBody('put', path, accessToken, body)
}

export async function deleteContent (id: number, accessToken: string): Promise<{}> {
  return requestDelete(`/contents/${id}`, accessToken)
}

export async function deleteText (id: number, accessToken: string): Promise<{}> {
  return requestDelete(`/texts/${id}`, accessToken)
}

export async function deleteNote (id: number, accessToken: string): Promise<{}> {
  return requestDelete(`/notes/${id}`, accessToken)
}

export async function getContent (id: number, accessToken: string): Promise<{
  content: Models.ContentRoot
  contents: Models.ContentEdge[]
}> {
  return requestGet(`/contents/${id}`, accessToken)
}

export async function getHome (accessToken: string): Promise<{
  texts: Models.TextContent[]
  contents: Models.ContentRoot[]
}> {
  return requestGet('/home', accessToken)
}

export async function getMe (accessToken: string): Promise<{
  me: Models.Me
}> {
  return requestGet('/me', accessToken)
}

export async function getText (id: number, accessToken: string): Promise<{
  text: Models.TextDetail & Models.RevisionNumber
}> {
  return requestGet(`/texts/${id}`, accessToken)
}

export async function getTextList (accessToken: string): Promise<{
  texts: Models.TextContent[]
}> {
  return requestGet('/texts/list', accessToken)
}

export async function getTextHisotry (id: number, accessToken: string): Promise<{
  textHistory: Models.TextHistory[]
}> {
  return requestGet(`/texts/${id}/history/list`, accessToken)
}

export async function getTextSnapshot (id: number, revision: number, accessToken: string): Promise<{
  textSnapshot: Models.TextHistoryDetail
}> {
  return requestGet(`/texts/${id}/history/${revision}`, accessToken)
}

export async function getRefreshTokenList (accessToken: string): Promise<{
  refreshTokens: Models.RefreshToken[]
}> {
  return requestGet('/refresh_tokens/list', accessToken)
}

export async function getNextPath <T> (nextPath: string, accessToken: string): Promise<T> {
  return requestGet(nextPath, accessToken)
}

export async function patchContent (
  id: number,
  targetDisplayOrder: number,
  accessToken: string
): Promise<{
  content: Models.ContentEdge
}> {
  return requestPatch(`/contents/${id}`, accessToken, {
    targetDisplayOrder
  })
}

export async function postContent (
  title: string | null,
  parentContentId: number | undefined,
  textId: number | undefined,
  text: string | undefined,
  accessToken: string
): Promise<{
  content: Models.ContentText
}> {
  return requestPost('/contents', accessToken, {
    title,
    parentContentId,
    textId,
    text
  })
}

export async function postNote (text: string, contentId: number | null, accessToken: string): Promise<{
  note: Models.Note
}> {
  return requestPost('/notes', accessToken, {
    text,
    contentId
  })
}

export async function postFeedback (type: 'help' | 'feedback', subtype: string, message: string, accessToken: string): Promise<{}> {
  return requestPost('/feedback', accessToken, {
    type,
    subtype,
    message
  })
}

export async function putContent (id: number, title: string, accessToken: string): Promise<{
  content: Models.Content
}> {
  return requestPut(`/contents/${id}`, accessToken, {
    title
  })
}

export async function putNote (id: number, text: string, accessToken: string): Promise<{
  note: Models.Note
}> {
  return requestPut(`/notes/${id}`, accessToken, {
    text
  })
}

export async function putText (id: number, document: string, accessToken: string): Promise<{
  text: Models.Text & Models.RevisionNumber
}> {
  return requestPut(`/texts/${id}`, accessToken, {
    document
  })
}

export async function putRefreshToken (id: number, expireMilliSeconds: number | undefined, accessToken: string): Promise<{
  refreshToken: Models.RefreshToken
}> {
  return requestPut(`/refresh_tokens/${id}`, accessToken, {
    expireMilliSeconds
  })
}
