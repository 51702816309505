import React from 'react'
import { ReactComponent as ChevronRightIcon } from '@material-design-icons/svg/filled/chevron_right.svg'
import { Link } from 'react-router-dom'

interface Props {
  type: 'home' | 'project'
}

const PartHeader: React.FC<Props> = props => {
  return <div className={'py-8px md:py-16px flex justify-between items-center align-bottom mr-12px min-h-heightSubHeader'}>
    <h2 className={'ml-16px'}>最近保存したテキスト</h2>
    {props.type === 'home' ? <Link to="/texts" className={'flex items-center align-bottom'}>すべて表示<ChevronRightIcon className={'ml-4px'}/></Link> : undefined}
  </div>
}

export default PartHeader
