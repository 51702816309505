import React from 'react'
import { Link, Route, Routes, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../app/hooks'
import { toggleSideMenu } from '../store/sideMenuSlice'
import { ReactComponent as Logo } from '../img/tateditor-icon.svg'
import { ReactComponent as MenuIcon } from '@material-design-icons/svg/filled/menu.svg'
import { ReactComponent as MoreVertIcon } from '@material-design-icons/svg/filled/more_vert.svg'
import { ReactComponent as LibraryBooksIcon } from '@material-design-icons/svg/filled/library_books.svg'
import { ReactComponent as FolderIcon } from '@material-design-icons/svg/filled/folder.svg'
import { ReactComponent as HomeIcon } from '@material-design-icons/svg/filled/home.svg'
import { ReactComponent as GavelIcon } from '@material-design-icons/svg/filled/gavel.svg'
import { ReactComponent as SecurityIcon } from '@material-design-icons/svg/filled/security.svg'
import { ReactComponent as VerifiedUserIcon } from '@material-design-icons/svg/filled/verified_user.svg'
import { ReactComponent as AccountCircleIcon } from '@material-design-icons/svg/filled/account_circle.svg'
import { ReactComponent as FeedbackIcon } from '@material-design-icons/svg/filled/feedback.svg'
import { ReactComponent as QuestionAnswerIcon } from '@material-design-icons/svg/filled/question_answer.svg'
import { ReactComponent as HelpIcon } from '@material-design-icons/svg/filled/help.svg'
import { ReactComponent as AppsIcon } from '@material-design-icons/svg/filled/apps.svg'
import { ReactComponent as HistoryIcon } from '@material-design-icons/svg/filled/history.svg'
import { ReactComponent as ShareIcon } from '@material-design-icons/svg/filled/share.svg'
import { ReactComponent as FileDownloadIcon } from '@material-design-icons/svg/filled/file_download.svg'
import { logout, selectHasLoggedIn } from '../store/userSlice'
import { showModal } from '../store/modalSlice'
import FeedbackModal from './modals/FeedbackModal'
import SearchBar from './editor/SearchBar'
import { download, downloadText, share } from '../app/editor'
import { selectText } from '../store/serverSlice'
import { TextData } from '../repository/Models'
import { showContextMenu } from '../store/contextMenuSlice'
import { AppDispatch } from '../app/store'
import TextHistoryModal from './modals/TextHistoryModal'

const AppLogo: React.FC<{
  isEditor?: true
}> = props => {
  return <div className={`${props.isEditor ? 'hidden md:flex' : 'flex'} justify-start w-184px shrink-0`}>
    <Link className={'flex items-center'} to="/"><Logo className={'hidden md:block rounded-4px mr-8px'} />TATEditor</Link>
  </div>
}

const AppHeader: React.FC<{
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  children: React.ReactNode
} | {
  isEditor: boolean
}> = props => {
  if ('icon' in props) {
    return <>
      <AppLogo />
      <h2 className={'hidden md:flex items-center text-24px font-bold'}>
        <props.icon className={'ml-24px mr-8px'} />{props.children}
      </h2>
    </>
  }
  if (props.isEditor) {
    return <>
      <AppLogo isEditor={true} />
      <SearchBar />
    </>
  }
  return <AppLogo />
}

const PageTitle: React.FC<{}> = () => {
  return <Routes>
    <Route path="/new" element={<AppHeader isEditor={true} />} />
    <Route path="/texts/:id/edit" element={<AppHeader isEditor={true} />} />
    <Route path="/projects/:id" element={<AppHeader icon={FolderIcon}>プロジェクト</AppHeader>} />
    <Route path="/texts" element={<AppHeader icon={LibraryBooksIcon}>テキスト一覧</AppHeader>} />
    <Route path="/home" element={<AppHeader icon={HomeIcon}>ホーム</AppHeader>} />
    <Route path="/contact" element={<AppHeader icon={QuestionAnswerIcon}>お問い合わせ</AppHeader>} />
    <Route path="/privacy" element={<AppHeader icon={SecurityIcon}>プライバシーポリシー</AppHeader>} />
    <Route path="/tos" element={<AppHeader icon={GavelIcon}>利用規約</AppHeader>} />
    <Route path="/me" element={<AppHeader icon={VerifiedUserIcon}>アカウント情報</AppHeader>} />
    <Route path="/help/*" element={<AppHeader icon={HelpIcon}>ヘルプ</AppHeader>} />
    <Route path="/auth/authorization" element={<AppHeader icon={AppsIcon}>アクセスの許可</AppHeader>} />
    <Route path="/auth/credentials" element={<AppHeader icon={AppsIcon}>アクセス権限の管理</AppHeader>} />
    <Route path="*" element={<AppHeader isEditor={false} />} />
  </Routes>
}

const DownloadText: React.FC<{
  text?: TextData
}> = props => {
  const dispatch = useAppDispatch()
  if ('share' in navigator) {
    return <ShareIcon
      title='シェア'
      className={'hidden md:block mx-4px p-8px w-40px h-40px rounded-full cursor-pointer hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'}
      onClick={e => downloadText(dispatch, e, props.text)}
    />
  } else {
    return <FileDownloadIcon
      title='ダウンロード'
      className={'hidden md:block mx-4px p-8px w-40px h-40px rounded-full cursor-pointer hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'}
      onClick={() => download(props.text)}
    />
  }
}

const DownloadContent: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>()
  const textId = parseInt(id || '0')
  const text = useAppSelector(state => selectText(state, textId))
  return <DownloadText text={text} />
}

const Feedback: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  return <FeedbackIcon
    title='フィードバック'
    className={'hidden md:block mx-4px p-8px w-40px h-40px rounded-full cursor-pointer hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'}
    onClick={() => dispatch(showModal({ component: FeedbackModal }))}
  />
}

const Account: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  // AccountCircleIconのp-4pxはSafariのバグ回避（padding:0だとsvgの最大描画領域にのみ背景色が反映される）
  return <AccountCircleIcon
    title='アカウント'
    className={'hidden md:block mx-8px p-4px w-56px h-56px rounded-full cursor-pointer hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'}
    onClick={e => dispatch(showContextMenu({
      position: {
        clientX: e.clientX,
        clientY: e.clientY
      },
      items: [
        {
          label: 'アカウント情報',
          path: '/me'
        },
        {
          label: 'ログアウト',
          onClick () {
            dispatch(logout())
          }
        }
      ]
    }))}
  />
}

function openTextHistory (dispatch: AppDispatch, text: TextData) {
  dispatch(showModal({
    component () {
      return <TextHistoryModal
        text={text}
      />
    }
  }))
}

const TextHistory: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>()
  const textId = parseInt(id || '0')
  const text = useAppSelector(state => selectText(state, textId))
  const dispatch = useAppDispatch()
  return <HistoryIcon
    title='保存履歴'
    className={'hidden md:block mx-4px p-8px w-40px h-40px rounded-full cursor-pointer hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'}
    onClick={e => {
      if (!text) {
        return
      }
      dispatch(showContextMenu({
        position: e,
        items: [
          {
            label: '保存履歴を表示',
            onClick () {
              openTextHistory(dispatch, text)
            }
          }
        ]
      }))
    }}
  />
}

function createTextMenu (dispatch: AppDispatch, text: TextData | undefined): ({
  label: string;
  onClick: React.MouseEventHandler<HTMLLIElement>;
} | {
  label: string;
  path: string;
})[] {
  const onFeedback = {
    label: 'フィードバック',
    onClick () {
      dispatch(showModal({ component: FeedbackModal }))
    }
  }
  const onShare = {
    label: 'シェア',
    onClick () {
      share(text)
    }
  }
  const onDownload = {
    label: 'ダウンロード',
    onClick () {
      download(text)
    }
  }
  const onOpenHistory = {
    label: '保存履歴を表示',
    onClick () {
      if (text) {
        openTextHistory(dispatch, text)
      }
    }
  }
  const menuItems = [onFeedback]
  if ('share' in navigator) {
    menuItems.push(onShare)
  }
  menuItems.push(onDownload)
  if (text) {
    menuItems.push(onOpenHistory)
  }
  return menuItems
}

const NewTextEditorMenu: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  return <MoreVertIcon
    className={'md:hidden mx-4px p-8px w-40px h-40px rounded-full cursor-pointer hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'}
    onClick={e => dispatch(showContextMenu({
      position: {
        clientX: e.clientX,
        clientY: e.clientY
      },
      items: createTextMenu(dispatch, undefined)
    }))}
  />
}

const TextEditorMenu: React.FC<{}> = () => {
  const { id } = useParams<{ id: string }>()
  const textId = parseInt(id || '0')
  const text = useAppSelector(state => selectText(state, textId))
  const dispatch = useAppDispatch()
  return <MoreVertIcon
    className={'md:hidden mx-4px p-8px w-40px h-40px rounded-full cursor-pointer hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'}
    onClick={e => {
      if (!text) {
        return
      }
      dispatch(showContextMenu({
        position: {
          clientX: e.clientX,
          clientY: e.clientY
        },
        items: createTextMenu(dispatch, text)
      }))
    }}
  />
}

const ActionItems: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  return <div className={'flex items-center text-24px shrink-0'}>
    <Routes>
      <Route path="/new" element={
        <>
          <DownloadText />
          <Account />
          <NewTextEditorMenu />
        </>
      } />
      <Route path="/texts/:id/edit" element={
        <>
          <TextHistory />
          <DownloadContent />
          <Account />
          <TextEditorMenu />
        </>
      } />
      <Route path="*" element={
        <>
          <Feedback />
          <Account />
          <MoreVertIcon
            className={'md:hidden mx-4px p-8px w-40px h-40px rounded-full cursor-pointer hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'}
            onClick={e => dispatch(showContextMenu({
              position: {
                clientX: e.clientX,
                clientY: e.clientY
              },
              items: [
                {
                  label: 'フィードバック',
                  onClick () {
                    dispatch(showModal({ component: FeedbackModal }))
                  }
                },
                {
                  label: 'アカウント情報',
                  path: '/me'
                },
                {
                  label: 'ログアウト',
                  onClick () {
                    dispatch(logout())
                  }
                }
              ]
            }))}
          />
        </>
      } />
    </Routes>
  </div>
}

const SignInButton: React.FC<{}> = () => {
  return <div>
    <Link to="/signin" className={'mr-12px'}>新規登録・ログイン</Link>
  </div>
}

const Header: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  const hasLoggedIn = useAppSelector(selectHasLoggedIn)
  if (hasLoggedIn) {
    return <div className={'flex mx-12px h-full items-center justify-between'}>
      <div className={'flex items-center flex-grow'}>
        <MenuIcon className={'p-12px mr-12px w-48px h-48px rounded-full cursor-pointer hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover shrink-0'} onClick={() => dispatch(toggleSideMenu())} />
        <PageTitle />
      </div>
      <ActionItems />
    </div>
  } else {
    return <div className={'flex mx-12px h-full items-center justify-between'}>
      <div className={'flex items-center flex-grow'}>
        <PageTitle />
      </div>
      <SignInButton />
    </div>
  }
}

export default Header
