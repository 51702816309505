import React from 'react'
import { useNavigate } from 'react-router-dom'

export interface Props {
  mode: 'maintenance' | 'disabledUser' | 'notFound' | 'error'
  onError: () => void
}

const ErrorContainer: React.FC<{
  cover: boolean
  message?: string
  buttonLabel: string
  onClick: () => void
}> = props => {
  return <div className={`fixed top-0 left-0 w-full h-full flex flex-col justify-center items-center ${props.cover ? 'pointer-events-auto' : 'pointer-events-none'}`}>
    {props.message ? <span>{props.message}</span> : undefined}
    <button className={'min-w-widthButton m-16px px-16px py-8px bg-accent text-white font-bold rounded-4px pointer-events-auto'} onClick={props.onClick}>{props.buttonLabel}</button>
  </div>
}

const LoadingError: React.FC<Props> = props => {
  const navigate = useNavigate()
  if (props.mode === 'disabledUser') {
    return <ErrorContainer
      cover={true}
      message={'退会処理中のアカウントです'}
      buttonLabel={'OK'}
      onClick={props.onError}
    />
  }
  if (props.mode === 'maintenance') {
    return <ErrorContainer
      cover={true}
      message={'メンテナンス中です'}
      buttonLabel={'再読み込み'}
      onClick={props.onError}
    />
  }
  if (props.mode === 'notFound') {
    return <ErrorContainer
      cover={false}
      message={'データが見つかりませんでした'}
      buttonLabel={'ホームに戻る'}
      onClick={() => navigate('/')}
    />
  }
  return <ErrorContainer
    cover={false}
    buttonLabel={'再読み込み'}
    onClick={props.onError}
  />
}

export default LoadingError
