import React, { useContext, useState } from 'react'
import { Models } from '../api/Models'
import PageLoad from '../components/loading/PageLoad'
import { fetchRefreshTokenList } from '../repository/Fetch'
import { ReactComponent as MoreVertIcon } from '@material-design-icons/svg/filled/more_vert.svg'
import { useAppDispatch } from '../app/hooks'
import { showContextMenu } from '../store/contextMenuSlice'
import { AppDispatch } from '../app/store'
import { updateRefreshToken } from '../repository/Update'
import { NetworkStateReducer, NetworkStoreContext } from '../store/NetworkStore'
import { showToast } from '../store/toastSlice'
import { dismissModal, showModal } from '../store/modalSlice'
import SelectModal from '../components/modals/SelectModal'

function createRefreshTokenMenu (
  refreshToken: Models.RefreshToken,
  dispatch: AppDispatch,
  networkStore: NetworkStateReducer,
  setState: React.Dispatch<React.SetStateAction<State | null>>
): ({
  label: string
  onClick: React.MouseEventHandler<HTMLLIElement>
} | {
  label: string
  path: string
})[] {
  const onRevoke = {
    label: '許可を取り消す',
    onClick () {
      updateRefreshToken(refreshToken.refreshTokenId, undefined, networkStore)
        .then(() => {
          setState(null)
        })
        .catch(() => {
          dispatch(showToast({ type: 'error', message: '通信に失敗しました' }))
        })
    }
  }
  const onUpdateExpiration = {
    label: '失効日時を設定する',
    onClick () {
      dispatch(showModal({
        component () {
          return <SelectModal
            message={'失効日数を選択してください'}
            label={'失効日数'}
            defaultValue={'365日'}
            optionList={['1日', '7日', '28日', '180日', '365日']}
            positive={{
              label: '設定',
              onSubmit (value: string) {
                dispatch(dismissModal())
                const day = value.match(/^([1-9][0-9]*)日$/)
                if (!day) {
                  return
                }
                updateRefreshToken(refreshToken.refreshTokenId, 1000 * 60 * 60 * 24 * parseInt(day[1], 10), networkStore)
                  .then(() => {
                    setState(null)
                  })
                  .catch(() => {
                    dispatch(showToast({ type: 'error', message: '通信に失敗しました' }))
                  })
              }
            }}
            negative={{
              label: 'キャンセル',
              onClick () {
                dispatch(dismissModal())
              }
            }}
          />
        }
      }))
    }
  }
  const menuItems = [onRevoke, onUpdateExpiration]
  return menuItems
}

const ItemLael: React.FC<{ children?: React.ReactNode }> = props => {
  return <div className={'my-4px mr-16px self-center'} style={{
    textAlign: 'start'
  }}>
    {props.children}
  </div>
}

const Item: React.FC<{ children: React.ReactNode }> = props => {
  return <div className={'my-4px mr-16px self-center overflow-x-auto'} style={{
    textAlign: 'start'
  }}>
    {props.children}
  </div>
}

interface State {
  refreshTokens: Models.RefreshToken[]
}

const Credentials: React.FC<{}> = () => {
  const [state, setState] = useState<State | null>(null)
  const dispatch = useAppDispatch()
  const networkStore = useContext(NetworkStoreContext)
  if (!state) {
    return <PageLoad<State>
      fetch={accessToken => fetchRefreshTokenList(accessToken)}
      dispatch={response => setState(response)}
    />
  }
  return <div className={'py-24px md:py-96px leading-document'}>
    <h2 className={'px-24px md:px-96px mb-16px text-24px'}>アクセス権限の管理</h2>
    <h3 className={'px-24px md:px-96px mt-32px mb-16px text-16px'}>連携アプリ</h3>
    {state.refreshTokens.length === 0 ? 'なし' : <div className={'px-24px md:px-96px flex overflow-x-auto max-w-full'}>
      <div className={'grid grid-cols-refreshTokens text-16px'}>
        <>
          <ItemLael>アプリ名</ItemLael>
          <ItemLael>発効日時</ItemLael>
          <ItemLael>失効日時</ItemLael>
          <ItemLael>クライアントID</ItemLael>
          <ItemLael></ItemLael>
        </>
        {state.refreshTokens.map(refreshToken => {
          return <React.Fragment key={refreshToken.refreshTokenId}>
            <Item>TATEditor for VS Code</Item>
            <Item>{new Date(refreshToken.refreshTokenCreatedTimestamp).toLocaleString()}</Item>
            <Item>{refreshToken.refreshTokenExpireTimestamp ? new Date(refreshToken.refreshTokenExpireTimestamp).toLocaleString() : '無期限'}</Item>
            <Item>{refreshToken.refreshTokenDeviceUuid}</Item>
            <Item><MoreVertIcon
              className={'p-8px w-40px h-40px rounded-full shrink-0 hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'}
              onClick={e => {
                dispatch(showContextMenu({
                  position: {
                    clientX: e.clientX,
                    clientY: e.clientY
                  },
                  items: createRefreshTokenMenu(refreshToken, dispatch, networkStore, setState)
                }))
              }}
            /></Item>
          </React.Fragment>
        })}
      </div>
    </div>}
  </div>
}

export default Credentials
