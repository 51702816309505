import React, { useEffect, useRef, useState } from 'react'
import { IdbSearch } from '../../database/searchHistory/SearchHistoryDatabase'
import { getAllIdbSearch, putIdbSearch } from '../../database/searchHistory/SearchHistoryDao'
import { searchInputId, searchWord } from '../../app/editor'
import SearchInput from './SearchInput'

function search (word: string, state: IdbSearch[], setState: React.Dispatch<React.SetStateAction<IdbSearch[]>>) {
  if (word.length === 0) {
    return
  }
  const oldSearch = state.find(value => value.word === word)
  const now = new Date()
  if (oldSearch) {
    const newSearch: IdbSearch = {
      ...oldSearch,
      searchCount: oldSearch?.searchCount + 1,
      updatedTimestamp: now.getTime()
    }
    setState([newSearch].concat(state.filter(search => search.word !== newSearch.word)))
    putIdbSearch(newSearch)
  } else {
    const newSearch: IdbSearch = {
      word,
      searchCount: 1,
      createdTimestamp: now.getTime(),
      updatedTimestamp: now.getTime()
    }
    setState([newSearch].concat(state))
    putIdbSearch(newSearch)
  }

  searchWord(word)
}

const SearchBar: React.FC<{}> = () => {
  const searchInput = useRef<HTMLInputElement>(null)
  const [state, setState] = useState<IdbSearch[]>([])
  useEffect(() => {
    getAllIdbSearch()
      .then(searchHistory => {
        setState(searchHistory)
      })
  }, [])
  return <form className={'flex-grow mr-8px md:mr-16px md:p-8px flex'} onSubmit={e => {
    e.preventDefault()
    const word = searchInput.current?.value
    search(word ?? '', state, setState)
  }}>
    <SearchInput ref={searchInput} type="text" id={searchInputId} className={'pl-16px md:pl-48px pr-96px w-full h-48px focus:bg-lightSurfaceFocus dark:focus:bg-darkSurfaceFocus rounded-8px shadow'}/>
    <section className={`${state.length === 0 ? 'hidden' : 'block'} max-h-240px overflow-y-scroll w-widthPanel max-w-widthPanel border-lightBorder dark:border-darkBorder bg-lightSurface dark:bg-darkSurface border rounded-8px transition-all absolute mt-heightHeader shadow`}>
      <ul className={'w-full p-8px'}>
        {state.map((history, i) => {
          return <li key={i}><button className={'w-full h-full px-8px py-4px text-left hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'} onMouseDown={() => {
            if (searchInput.current) {
              searchInput.current.value = history.word
            }
            setTimeout(() => {
              // happen nothing without setTimeout
              search(history.word, state, setState)
            })
          }}>{history.word}</button></li>
        })}
      </ul>
    </section>
    <button type="submit" className={'-ml-80px md:-ml-96px p-8px w-80px text-12px'}>次を検索</button>
  </form>
}

export default SearchBar
