import React, { useEffect, useState } from 'react'
import { ReactComponent as ChevronRightIcon } from '@material-design-icons/svg/filled/chevron_right.svg'
import { ReactComponent as ExpandMoreIcon } from '@material-design-icons/svg/filled/expand_more.svg'
import { ReactComponent as RefreshIcon } from '@material-design-icons/svg/filled/refresh.svg'
import { Link, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { fetchHome, fetchProject } from '../../repository/Fetch'
import { parentTitleOfText, ProjectListItemData, SectionListItemData, TextListItemData, titleOfProject, titleOfSection, titleOfText } from '../../repository/Models'
import { selectAllProjects, selectProject, selectText, setHome, setProject } from '../../store/serverSlice'
import { LoadProps, useLoading } from '../loading/PageLoad'
import LoadingIcon from '../loading/LoadingIcon'
import { getCurrentEditorValue, moveCaret } from '../../app/editor'
import { selectEditUuid } from '../../store/editorSlice'
import { closeSideMenu } from '../../store/sideMenuSlice'

const SideMenuList: React.FC<{ children: React.ReactNode }> = props => {
  return <li className={'py-8px pl-16px w-full text-16px leading-16px border-b last:border-b-0 border-lightBorder dark:border-darkBorder'}><ul>{props.children}</ul></li>
}

const SideMenuLinkListItem: React.FC<{
  to: string
  children: React.ReactNode
}> = props => {
  return <li className={'flex items-center w-full h-40px hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'}><Link className={'inline-block w-full h-full p-12px overflow-x-auto whitespace-nowrap'} to={props.to}>{props.children}</Link></li>
}

const SideMenuListItem: React.FC<{ children: React.ReactNode }> = props => {
  return <li className={'flex items-center w-full h-40px'}><p className={'inline-block w-full h-full p-12px overflow-x-auto whitespace-nowrap'}>{props.children}</p></li>
}

const SideMenuLoadingListItem: React.FC<{}> = () => {
  return <li className={'flex items-center h-40px px-12px'}><LoadingIcon size={'small'}/></li>
}

const SideMenuActionListItem: React.FC<{
  onClick: () => void
  children: React.ReactNode
}> = props => {
  return <li onClick={props.onClick} className={'flex items-center w-full h-40px hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover cursor-pointer'}><p className={'inline-block w-full h-full p-12px overflow-x-auto whitespace-nowrap'}>{props.children}</p></li>
}

interface State {
  home: {
    recentTexts: TextListItemData[]
    allProjects: ProjectListItemData[]
  }
}

const ToggleList: React.FC<{
  title: string
  isOpen?: true
  children: React.ReactNode
}> = props => {
  const [isOpen, setOpen] = useState(props.isOpen ?? false)
  return <li>
    <div className={'flex items-center w-full h-40px hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover cursor-pointer'} onClick={() => {
      setOpen(!isOpen)
    }}>{isOpen ? <ExpandMoreIcon style={{ width: '25px', height: '25px' }}/> : <ChevronRightIcon style={{ width: '25px', height: '25px' }}/>}<p className={'inline-block h-full p-12px pr-0 overflow-x-auto whitespace-nowrap'}>{props.title}</p></div>
    <ul className={isOpen ? 'ml-12px border-l border-lightBorder dark:border-darkBorder' : 'hidden'}>
      {props.children}
    </ul>
  </li>
}

function SideMenuLoadListItem<T> (props: LoadProps<T>): React.ReactElement<any, any> | null {
  const [loadingStatus, setWaiting] = useLoading(props)
  if (loadingStatus === 'error' || loadingStatus === 'maintenance') {
    return <li onClick={setWaiting} className={'flex items-center w-full h-40px hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover cursor-pointer'}><p className={'inline-block h-full p-12px pr-0 overflow-x-auto whitespace-nowrap'}>再読み込み</p><RefreshIcon/></li>
  }
  return <SideMenuLoadingListItem/>
}

const ProjectList: React.FC<{}> = () => {
  const dispatch = useAppDispatch()
  const projects = useAppSelector(selectAllProjects)
  if (!projects) {
    return <SideMenuLoadListItem<State>
      fetch={accessToken => fetchHome(accessToken)}
      dispatch={response => dispatch(setHome(response))}
    />
  }
  return <ToggleList title={`プロジェクト一覧 (${projects.length})`}>
    {projects.map((project) => {
      return <SideMenuLinkListItem key={project.id} to={project.text ? `/texts/${project.text.id}/edit` : `/projects/${project.id}`}>{titleOfProject(project)}</SideMenuLinkListItem>
    })}
  </ToggleList>
}

const ProjectSectionList: React.FC<{
  projectId: number
}> = props => {
  const dispatch = useAppDispatch()
  const project = useAppSelector(state => selectProject(state, props.projectId))
  if (!project) {
    return <SideMenuLoadListItem<{
      project: {
        project: ProjectListItemData
        sections: SectionListItemData[]
      }
    }>
      fetch={accessToken => fetchProject(props.projectId, accessToken)}
      dispatch={response => dispatch(setProject(response.project))}
    />
  }
  return <ToggleList title={`プロジェクト情報 (${project.sections.length})`}>
    {project.sections.map((section) => {
      if (!section.text) {
        return <SideMenuListItem key={section.id}>{titleOfSection(section)}</SideMenuListItem>
      }
      return <SideMenuLinkListItem key={section.id} to={`/texts/${section.text.id}/edit`}>{titleOfSection(section)}</SideMenuLinkListItem>
    })}
  </ToggleList>
}

const SectionList: React.FC<{
  textId: number
}> = props => {
  const text = useAppSelector(state => selectText(state, props.textId))
  if (!text) {
    return <SideMenuLoadingListItem/>
  }
  if (!text.project) {
    return null
  }
  return <ProjectSectionList projectId={text.project.id}/>
}

const ProjectName: React.FC<{
  textId: number
}> = props => {
  const text = useAppSelector(state => selectText(state, props.textId))
  if (!text) {
    return <SideMenuLoadingListItem/>
  }
  if (!text.project) {
    return <SideMenuListItem>{titleOfText(text)}</SideMenuListItem>
  }
  return <SideMenuLinkListItem to={`/projects/${text.project.id}`}>{parentTitleOfText(text)}</SideMenuLinkListItem>
}

interface OutlineItem {
  position: number
  title: string
}

function calcOutline (text: string): OutlineItem[] {
  const regexp = /(^[#.].*$|\n\n\n.+$)/gm
  const outlineItems: OutlineItem[] = []
  while (true) {
    const match = regexp.exec(text)
    if (match === null) {
      break
    }
    const matchLength = match[0].length
    const title = match[0].trimStart()
    outlineItems.push({
      position: match.index + (matchLength - title.length),
      title
    })
  }
  return outlineItems
}

const TextOutline: React.FC<{}> = () => {
  const editUuid = useAppSelector(selectEditUuid)
  const editingText = getCurrentEditorValue()
  const [outline, setOutline] = useState<OutlineItem[]>([])
  useEffect(() => {
    const calcOutlineTimeout = setTimeout(() => {
      setOutline(calcOutline(editingText))
    }, 300)
    return () => {
      clearTimeout(calcOutlineTimeout)
    }
  }, [editingText, editUuid])
  const dispatch = useAppDispatch()
  return <ToggleList title={'アウトライン'} isOpen={true}>
    {outline.map((item, i) => {
      return <SideMenuActionListItem key={i} onClick={() => {
        moveCaret(item.position, item.position)
        if (!window.matchMedia('(min-width: 768px)').matches) {
          dispatch(closeSideMenu())
        }
      }}>{item.title}</SideMenuActionListItem>
    })}
  </ToggleList>
}

const OutlineList: React.FC<{}> = () => {
  const { id } = useParams<{ id?: string }>()
  if (id) {
    const textId = parseInt(id || '0')
    return <>
      <SideMenuList>
        <ProjectName textId={textId}/>
      </SideMenuList>
      <SideMenuList>
        <ProjectList/>
        <SectionList textId={textId}/>
        <TextOutline/>
      </SideMenuList>
    </>
  } else {
    return <SideMenuList>
      <ProjectList/>
      <TextOutline/>
    </SideMenuList>
  }
}

export default OutlineList
