import React from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import OutlineList from './editor/OutlineList'

const SideMenuList: React.FC<{ children: React.ReactNode }> = props => {
  return <li className={'py-8px pl-16px w-full text-16px leading-16px border-b last:border-b-0 border-lightBorder dark:border-darkBorder'}><ul>{props.children}</ul></li>
}

const SideMenuLinkListItem: React.FC<{
  to: string
  children: React.ReactNode
}> = props => {
  return <li className={'flex items-center w-full h-40px hover:bg-lightSurfaceSecondaryHover dark:hover:bg-darkSurfaceSecondaryHover'}><Link className={'inline-block w-full h-full p-12px overflow-x-auto whitespace-nowrap'} to={props.to}>{props.children}</Link></li>
}

const SideMenuFooter: React.FC<{ children: React.ReactNode }> = props => {
  return <div className={'absolute bottom-0 p-16px text-10px'}>{props.children}</div>
}

const SideMenu: React.FC<{}> = () => {
  return <>
    <ul className={'block h-full pb-48px overflow-y-scroll'}>
      <SideMenuList>
        <SideMenuLinkListItem to="/home">ホーム</SideMenuLinkListItem>
        <SideMenuLinkListItem to="/texts">すべてのテキスト</SideMenuLinkListItem>
      </SideMenuList>
      <Routes>
        <Route path="/new" element={<OutlineList/>} />
        <Route path="/texts/:id/edit" element={<OutlineList/>} />
      </Routes>
      <SideMenuList>
        <SideMenuLinkListItem to="/about">TATEditorについて</SideMenuLinkListItem>
        <SideMenuLinkListItem to="/help">ヘルプ</SideMenuLinkListItem>
      </SideMenuList>
    </ul>
    <SideMenuFooter>
      <Link to="/tos">利用規約</Link>・<Link to="/privacy">プライバシーポリシー</Link>・<Link to="/contact">お問い合わせ</Link>
    </SideMenuFooter>
  </>
}

export default SideMenu
