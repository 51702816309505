import React from 'react'
import { Helmet } from 'react-helmet'
import { Route, Routes } from 'react-router-dom'

const Head: React.FC<{}> = () => {
  return <Routes>
    <Route path="/new" element={
      <Helmet>
        <title>新規テキスト - TATEditor</title>
      </Helmet>
    } />
    <Route path="/texts/:id/edit" element={
      <Helmet>
        <title>編集 - TATEditor</title>
      </Helmet>
    } />
    <Route path="/projects/:id" element={
      <Helmet>
        <title>プロジェクト - TATEditor</title>
      </Helmet>
    } />
    <Route path="/texts" element={
      <Helmet>
        <title>テキスト一覧 - TATEditor</title>
      </Helmet>
    } />
    <Route path="/home" element={
      <Helmet>
        <title>ホーム - TATEditor</title>
      </Helmet>
    } />
    <Route path="/contact" element={
      <Helmet>
        <title>お問い合わせ - TATEditor</title>
      </Helmet>
    } />
    <Route path="/privacy" element={
      <Helmet>
        <title>プライバシーポリシー - TATEditor</title>
      </Helmet>
    } />
    <Route path="/tos" element={
      <Helmet>
        <title>利用規約 - TATEditor</title>
      </Helmet>
    } />
    <Route path="/me" element={
      <Helmet>
        <title>アカウント情報 - TATEditor</title>
      </Helmet>
    } />
    <Route path="/help/*" element={
      <Helmet>
        <title>ヘルプ - TATEditor</title>
      </Helmet>
    } />
    <Route path="/auth/authorization" element={
      <Helmet>
        <title>アクセスの許可 - TATEditor</title>
      </Helmet>
    } />
    <Route path="/auth/credentials" element={
      <Helmet>
        <title>アクセス権限の管理 - TATEditor</title>
      </Helmet>
    } />
    <Route path="*" element={
      <Helmet>
        <title>TATEditor</title>
      </Helmet>
    } />
  </Routes>
}

export default Head
