import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Models } from '../api/Models'
import type { RootState } from '../app/store'
import { ProjectListItemData, SectionListItemData, TextData, TextListItemData, TextSectionListItemData } from '../repository/Models'

// Define a type for the slice state
interface ServerState {
  allProjects?: ProjectListItemData[]
  texts?: {
    isAll: boolean
    list: TextListItemData[]
  }
  textMap: {
    [key: number]: {
      data: TextData
      fetchTimestamp: number
    }
  }
  textHistoryMap: {
    [key: number]: {
      data: Models.TextHistory[]
      nextPath?: string
      fetchTimestamp: number
    }
  }
  textHistorySnapshotMap: {
    [key: number]: {
      [key: number]: Models.TextHistoryDetail
    }
  }
  projectMap: {
    [key: number]: {
      project: ProjectListItemData
      sections: SectionListItemData[]
    }
  }
}

// Define the initial state using that type
const initialState: ServerState = {
  textMap: {},
  projectMap: {},
  textHistoryMap: {},
  textHistorySnapshotMap: {}
}

function updateRootText (oldProject: ProjectListItemData, newText: TextData): ProjectListItemData {
  return {
    id: oldProject.id,
    title: oldProject.title,
    savedTimestamp: newText.savedTimestamp,
    createdTimestamp: oldProject.createdTimestamp,
    lineCount: newText.lineCount,
    characterCount: newText.characterCount,
    textCount: oldProject.textCount,
    text: {
      id: newText.id,
      savedTimestamp: newText.savedTimestamp,
      createdTimestamp: oldProject.createdTimestamp,
      lineCount: newText.lineCount,
      characterCount: newText.characterCount
    }
  }
}

function updateProject (oldProject: ProjectListItemData, oldText: TextData, newText: TextData): ProjectListItemData {
  return {
    id: oldProject.id,
    title: oldProject.title,
    savedTimestamp: newText.savedTimestamp,
    createdTimestamp: oldProject.createdTimestamp,
    lineCount: oldProject.lineCount + newText.lineCount - oldText.lineCount,
    characterCount: oldProject.characterCount + newText.characterCount - oldText.characterCount,
    textCount: oldProject.textCount,
    text: {
      id: newText.id,
      savedTimestamp: newText.savedTimestamp,
      createdTimestamp: newText.createdTimestamp,
      lineCount: newText.lineCount,
      characterCount: newText.characterCount
    }
  }
}

function updateProjectSection (oldProject: ProjectListItemData, newSection: TextSectionListItemData): ProjectListItemData {
  return {
    ...oldProject,
    savedTimestamp: newSection.text.savedTimestamp,
    characterCount: oldProject.characterCount + newSection.text.characterCount,
    lineCount: oldProject.lineCount + newSection.text.lineCount,
    textCount: oldProject.textCount + 1
  }
}

function updateSections (sections: SectionListItemData[], newText: TextData): SectionListItemData[] {
  const displayNumber = newText.project?.edge.displayNumber
  if (!displayNumber) {
    return sections
  }
  return sections.map(section => {
    if (section.id !== newText.content.id) {
      return section
    }
    const newSection: SectionListItemData = {
      ...section,
      displayNumber,
      text: {
        id: newText.id,
        savedTimestamp: newText.savedTimestamp,
        createdTimestamp: newText.createdTimestamp,
        lineCount: newText.lineCount,
        characterCount: newText.characterCount
      }
    }
    return newSection
  })
}

function hasUpdate (oldTexts: TextListItemData[], newTexts: TextListItemData[]): boolean {
  if (oldTexts.length < newTexts.length) {
    return true
  }
  return newTexts.some((text, i) => {
    const oldText = oldTexts[i]
    return text.id !== oldText.id ||
    text.lineCount !== oldText.lineCount ||
    text.characterCount !== oldText.characterCount ||
    text.savedTimestamp !== oldText.savedTimestamp ||
    text.content.id !== oldText.content.id ||
    text.content.title !== oldText.content.title ||
    text.project !== oldText.project ||
    text.project?.id !== oldText.project?.id ||
    text.project?.title !== oldText.project?.title ||
    text.project?.edge !== oldText.project?.edge ||
    text.project?.edge.displayNumber !== oldText.project?.edge.displayNumber
  })
}

export const serverSlice = createSlice({
  name: 'serverSlice',
  initialState,
  reducers: {
    setTextHistorySnapshot: (state, action: PayloadAction<Models.TextHistoryDetail>) => {
      if (action.payload.textHistoryTextId in state.textHistorySnapshotMap) {
        state.textHistorySnapshotMap[action.payload.textHistoryTextId][action.payload.textHistoryRevisionNumber] = action.payload
      } else {
        state.textHistorySnapshotMap[action.payload.textHistoryTextId] = {}
        state.textHistorySnapshotMap[action.payload.textHistoryTextId][action.payload.textHistoryRevisionNumber] = action.payload
      }
    },
    setTextHistory: (state, action: PayloadAction<{
      id: number,
      data: {
        textHistory: Models.TextHistory[]
        nextPath?: string
      }
    }>) => {
      state.textHistoryMap[action.payload.id] = {
        data: action.payload.data.textHistory,
        nextPath: action.payload.data.nextPath,
        fetchTimestamp: new Date().getTime()
      }
    },
    addTextHistory: (state, action: PayloadAction<{
      id: number,
      data: {
        textHistory: Models.TextHistory[]
        nextPath?: string
      }
    }>) => {
      if (action.payload.id in state.textHistoryMap) {
        state.textHistoryMap[action.payload.id].data.push(...action.payload.data.textHistory)
        state.textHistoryMap[action.payload.id].nextPath = action.payload.data.nextPath
      }
    },
    setHome: (state, action: PayloadAction<{
      home: {
        recentTexts: TextListItemData[]
        allProjects: ProjectListItemData[]
      }
    }>) => {
      // update projectMap?
      // update projects
      state.allProjects = action.payload.home.allProjects
      // update textMap?
      // update texts?
      if (!state.texts || !state.texts.isAll || hasUpdate(state.texts.list, action.payload.home.recentTexts)) {
        state.texts = {
          isAll: false,
          list: action.payload.home.recentTexts
        }
      }
    },
    setTexts: (state, action: PayloadAction<TextListItemData[]>) => {
      // update projectMap?
      // update projects?
      // update textMap?
      // update texts
      state.texts = {
        isAll: true,
        list: action.payload
      }
    },
    replaceText: (state, action: PayloadAction<TextData>) => {
      if (action.payload.id in state.textMap) {
        const oldText = state.textMap[action.payload.id]
        const newText = action.payload
        const parentId = newText.project?.id
        if (parentId) {
          // update projectMap
          if (parentId in state.projectMap) {
            state.projectMap[parentId].project = updateProject(state.projectMap[parentId].project, oldText.data, newText)
            state.projectMap[parentId].sections = updateSections(state.projectMap[parentId].sections, newText)
          }
          // update projects
          if (state.allProjects) {
            state.allProjects = state.allProjects.map(project => {
              return project.id !== parentId ? project : updateProject(project, oldText.data, newText)
            }).sort((a, b) => -(a.savedTimestamp - b.savedTimestamp))
          }
        } else if (state.allProjects) {
          // no update projectMap
          // update projects
          state.allProjects = state.allProjects.map(project => {
            return project.id !== newText.content.id ? project : updateRootText(project, newText)
          }).sort((a, b) => -(a.savedTimestamp - b.savedTimestamp))
        }
      }
      // update textMap
      state.textMap[action.payload.id] = {
        data: action.payload,
        fetchTimestamp: new Date().getTime()
      }
      // update texts
      if (state.texts) {
        const newText: TextListItemData = {
          id: action.payload.id,
          savedTimestamp: action.payload.savedTimestamp,
          createdTimestamp: action.payload.createdTimestamp,
          lineCount: action.payload.lineCount,
          characterCount: action.payload.characterCount,
          content: action.payload.content,
          project: action.payload.project
        }
        state.texts.list = state.texts.list.map(text => {
          return text.id !== action.payload.id ? text : newText
        }).sort((a, b) => -(a.savedTimestamp - b.savedTimestamp))
      }
    },
    setProject: (state, action: PayloadAction<{
      project: ProjectListItemData
      sections: SectionListItemData[]
    }>) => {
      // update projectMap
      state.projectMap[action.payload.project.id] = action.payload
      // update projects?
      if (state.allProjects) {
        state.allProjects = state.allProjects.map(project => {
          return project.id !== action.payload.project.id ? project : action.payload.project
        }).sort((a, b) => -(a.savedTimestamp - b.savedTimestamp))
      }
      // update texts?
    },
    reorderSection: (state, action: PayloadAction<{
      project: ProjectListItemData
      newSection: SectionListItemData
      newIndex: number
    }>) => {
      if (action.payload.project.id in state.projectMap) {
        const sections = state.projectMap[action.payload.project.id].sections.map((section, i) => {
          if (i < action.payload.newIndex) {
            return section
          } else {
            if (section.displayNumber) {
              return {
                ...section,
                displayNumber: section.displayNumber + 1
              }
            }
            return section
          }
        }).filter(section => section.id !== action.payload.newSection.id)
        sections.splice(action.payload.newIndex, 0, action.payload.newSection)
        state.projectMap[action.payload.project.id].sections = sections
      }
    },
    addEmptyProject (state, action: PayloadAction<ProjectListItemData>) {
      if (action.payload.text) {
        throw new Error('Logic error')
      }
      // update projectMap
      state.projectMap[action.payload.id] = {
        project: action.payload,
        sections: []
      }
      // update projects
      if (state.allProjects) {
        state.allProjects.splice(0, 0, action.payload)
      }
    },
    addNewText (state, action: PayloadAction<TextData>) {
      // update textMap
      state.textMap[action.payload.id] = {
        data: action.payload,
        fetchTimestamp: new Date().getTime()
      }
      const text: TextListItemData = {
        id: action.payload.id,
        savedTimestamp: action.payload.savedTimestamp,
        createdTimestamp: action.payload.createdTimestamp,
        lineCount: action.payload.lineCount,
        characterCount: action.payload.characterCount,
        content: action.payload.content,
        project: action.payload.project
      }
      // update texts
      if (state.texts) {
        state.texts.list.splice(0, 0, text)
      }
      if (text.project) {
        const newSection: TextSectionListItemData = {
          id: text.content.id,
          title: text.content.title,
          createdTimestamp: text.createdTimestamp, // dummy
          displayNumber: text.project.edge.displayNumber,
          text: {
            id: text.id,
            savedTimestamp: text.savedTimestamp,
            createdTimestamp: text.createdTimestamp,
            lineCount: text.lineCount,
            characterCount: text.characterCount
          }
        }
        // update projectMap
        if (text.project.id in state.projectMap) {
          state.projectMap[text.project.id].project = updateProjectSection(state.projectMap[text.project.id].project, newSection)
          state.projectMap[text.project.id].sections.push(newSection)
        }
        // update projects
        if (state.allProjects) {
          const projectId = text.project.id
          state.allProjects = state.allProjects.map(project => {
            return project.id !== projectId ? project : updateProjectSection(project, newSection)
          }).sort((a, b) => -(a.savedTimestamp - b.savedTimestamp))
        }
      } else if (state.allProjects) {
        // update projects
        const project: ProjectListItemData = {
          ...text.content,
          savedTimestamp: text.savedTimestamp,
          createdTimestamp: text.createdTimestamp, // dummy
          characterCount: text.characterCount,
          lineCount: text.lineCount,
          textCount: 1,
          text: {
            id: text.id,
            savedTimestamp: text.savedTimestamp,
            createdTimestamp: text.createdTimestamp,
            characterCount: text.characterCount,
            lineCount: text.lineCount
          }
        }
        state.allProjects = state.allProjects.splice(0, 0, project)
      }
    },
    updateTitle (state, action: PayloadAction<{
      contentId: number
      textId: number | undefined
      parentId: number | undefined
      title: string | null
    }>) {
      // update projectMap
      // for project update
      if (action.payload.contentId in state.projectMap) {
        state.projectMap[action.payload.contentId].project.title = action.payload.title
      }
      // for section update
      if (action.payload.parentId && action.payload.parentId in state.projectMap) {
        state.projectMap[action.payload.parentId].sections = state.projectMap[action.payload.parentId].sections.map(section => {
          return section.id !== action.payload.contentId ? section : {
            ...section,
            title: action.payload.title
          }
        })
      }
      // update projects
      if (state.allProjects) {
        state.allProjects = state.allProjects.map(project => {
          return project.id !== action.payload.contentId ? project : {
            ...project,
            title: action.payload.title
          }
        })
      }
      // update textMap
      // for text update
      if (action.payload.textId) {
        if (action.payload.textId in state.textMap) {
          state.textMap[action.payload.textId].data.content.title = action.payload.title
        }
      }
      // for parent update
      Object.keys(state.textMap).forEach(key => {
        if (typeof key === 'number') {
          const project = state.textMap[key].data.project
          if (project && project.id === action.payload.contentId) {
            state.textMap[key].data.project = {
              ...project,
              title: action.payload.title
            }
          }
        }
      })
      // update texts
      if (state.texts) {
        state.texts.list = state.texts.list.map(text => {
          // for text update
          if (text.content.id === action.payload.contentId) {
            return {
              ...text,
              content: {
                id: action.payload.contentId,
                title: action.payload.title
              }
            }
          }
          // for parent update
          if (text.project?.id === action.payload.contentId) {
            return {
              ...text,
              project: {
                ...text.project,
                title: action.payload.title
              }
            }
          }
          return text
        })
      }
    },
    deleteContent (state, action: PayloadAction<{
      contentId: number
      text: {
        id: number
        lineCount: number
        characterCount: number
      } | null
      parentId: number | undefined
    }>) {
      // update projectMap
      // for project delete
      if (action.payload.contentId in state.projectMap) {
        delete state.projectMap[action.payload.contentId]
      }
      // for section delete
      if (action.payload.parentId && action.payload.parentId in state.projectMap) {
        const index = state.projectMap[action.payload.parentId].sections.findIndex(section => section.id === action.payload.contentId)
        const section = state.projectMap[action.payload.parentId].sections[index]
        state.projectMap[action.payload.parentId].sections.splice(index, 1)
        if (section.text) {
          state.projectMap[action.payload.parentId].project = {
            ...state.projectMap[action.payload.parentId].project,
            characterCount: state.projectMap[action.payload.parentId].project.lineCount - section.text.characterCount,
            lineCount: state.projectMap[action.payload.parentId].project.lineCount - section.text.lineCount,
            textCount: state.projectMap[action.payload.parentId].project.textCount - 1
          }
          state.projectMap[action.payload.parentId].sections = state.projectMap[action.payload.parentId].sections.map((section, i) => {
            if (!section.displayNumber) {
              return section
            }
            return i < index ? section : {
              ...section,
              displayNumber: section.displayNumber - 1
            }
          })
        }
      }
      const text = action.payload.text
      // update projects
      if (state.allProjects) {
        // for project delete
        state.allProjects = state.allProjects.filter(project => project.id !== action.payload.contentId)
        if (text) {
          // for section delete
          state.allProjects = state.allProjects.map(project => {
            if (project.id === action.payload.parentId) {
              return {
                ...project,
                characterCount: project.lineCount - text.characterCount,
                lineCount: project.lineCount - text.lineCount,
                textCount: project.textCount - 1
              }
            }
            return project
          })
        }
      }
      // update textMap
      // for text delete
      if (action.payload.text) {
        if (action.payload.text.id in state.textMap) {
          delete state.textMap[action.payload.text.id]
        }
      }
      // for parent delete
      Object.keys(state.textMap).forEach(key => {
        if (typeof key === 'number' && (state.textMap[key].data.project?.id === action.payload.contentId)) {
          delete state.textMap[key]
        }
      })
      // update texts
      if (state.texts) {
        state.texts.list = state.texts.list.filter(text => {
          // for text and parent delete
          return !(text.content.id === action.payload.contentId || text.project?.id === action.payload.contentId)
        })
      }
    }
  }
})

export const { setHome, setTextHistory, addTextHistory, setTextHistorySnapshot, setTexts, replaceText, setProject, reorderSection, addEmptyProject, addNewText, updateTitle, deleteContent } = serverSlice.actions

export const selectTextHistorySnapshot = (state: RootState, textId: number, revisionNumber: number) => {
  if (textId in state.serverSlice.textHistorySnapshotMap) {
    return state.serverSlice.textHistorySnapshotMap[textId][revisionNumber]
  }
  return undefined
}

export const selectTextHistory = (state: RootState, textId: number) => {
  if (textId in state.serverSlice.textHistoryMap) {
    return state.serverSlice.textHistoryMap[textId]
  }
  return undefined
}

export const selectAllProjects = (state: RootState) => {
  if (!state.serverSlice.allProjects) {
    return undefined
  }
  return state.serverSlice.allProjects
}

export const selectAllTexts = (state: RootState) => {
  if (!state.serverSlice.texts || !state.serverSlice.texts.isAll) {
    return undefined
  }
  return state.serverSlice.texts.list
}

export const selectHome = (state: RootState) => {
  if (!state.serverSlice.allProjects || !state.serverSlice.texts) {
    return undefined
  }
  return {
    home: {
      recentTexts: state.serverSlice.texts.list.slice(0, 3),
      allProjects: state.serverSlice.allProjects
    }
  }
}

export const selectText = (state: RootState, textId: number): TextData | undefined => {
  if (textId in state.serverSlice.textMap) {
    const text = state.serverSlice.textMap[textId]
    return text.data
  }
  return undefined
}

export const selectTextTimestamp = (state: RootState, textId: number): number | undefined => {
  if (textId in state.serverSlice.textMap) {
    const text = state.serverSlice.textMap[textId]
    return text.fetchTimestamp
  }
  return undefined
}

export const selectProject = (state: RootState, projectId: number): {
  project: ProjectListItemData
  sections: SectionListItemData[]
} | undefined => {
  return state.serverSlice.projectMap[projectId]
}

export default serverSlice.reducer
